


import React, {useState, useEffect, useCallback} from 'react';
import { Platform, View, Text } from "react-native";

import {
    usePlaidLink,
    PlaidLinkOptions,
    PlaidLinkOnSuccess,
  } from 'react-plaid-link';

export function CustomPlaidLink () {

  const [linkToken, setLinkToken] = useState(null);
  // const address = Platform.OS === 'ios' ? 'http://localhost:8000' : '';
  // const address = 'localhost';
  const address = 'https://api.lofi.money';

  const createLinkToken = useCallback(async () => {
    await fetch(`${address}/api/plaid/create_link_token`, {
    method: "POST",
    })
    .then((response) => response.json())
    .then((data) => {
      setLinkToken(data.link_token);
    })
    .catch((err) => {
      console.log(err);
    });
  }, [setLinkToken])
  useEffect(() => {
    if (linkToken == null) {
      createLinkToken();
    }
  }, [linkToken]);


    const { open, ready } = usePlaidLink({
        token: linkToken,
        onSuccess: (public_token, metadata) => {
            console.log("SUCCESS")
          // send public_token to server
        },
      });


    return (
        <div>
            <button onClick={() => open()} disabled={!ready}>
                Add account
            </button>
        </div>
    )
}