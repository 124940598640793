

import React, {useState, useEffect, useCallback} from 'react';
import { Platform, View, Text, Button, Pressable, TextInput, ScrollView } from 'react-native';
// import {PlaidLink } from 'react-native-plaid-link-sdk';
// import { PlaidLink } from 'react-plaid-link';
import { ScreenContainer } from '../components/ScreenContainer/ScreenContainer';
import { TransactionCard } from '../components/TransactionCard';
import { Link } from '@react-navigation/native';
import { TransactionModal } from '../components/TransactionModal';
// import { listTransactions } from '../data/transactions';
import { useGlobalState } from '../store/AuthStore';
import { listCategories } from '../data/categories';
// import { refreshTransactions } from '../data/transactions';
import { useRecoilValue, useSetRecoilState, useRecoilRefresher_UNSTABLE } from 'recoil';
import { categories, monthsCategoryTotals, monthsProcessedTransactions, monthsUnprocessedTransactions, currentMonthYear, currentMonthText, CURRENT_MONTH, CURRENT_YEAR, transactions } from '../store/TransactionStore';

import Ionicons from '@expo/vector-icons/Ionicons';



export const HomeScreen = ({navigation}) => {
  const [state, dispatch] = useGlobalState();
  const [modalVisible, setModalVisible] = useState(false);

  // const [categories, setCategories] = React.useState([]);
  // const [newTransactions, setNewTransactions] = useState([]);
  // const [processedTransactions, setProcessedTransactions] = useState([]);
  const newTransactions = useRecoilValue(monthsUnprocessedTransactions);
  const processedTransactions = useRecoilValue(monthsProcessedTransactions);
  const categoriesList = useRecoilValue(categories);
  const categoriesTotalsList = useRecoilValue(monthsCategoryTotals);
  const currentSelectedMonthYear = useRecoilValue(currentMonthYear)
  const setCurrentMonthYear = useSetRecoilState(currentMonthYear);
  const monthText = useRecoilValue(currentMonthText)
  const refreshTransactionState = useRecoilRefresher_UNSTABLE(transactions);

  const [transactionToReview, setTransactionToReview] = useState(newTransactions[0]);


  function handleModalClose(refreshTransactions) {
    if (refreshTransactions){
      // fetchTransactionData()
      refreshTransactionState()
      setTransactionToReview(newTransactions[0])
    }
    setModalVisible(false)
    setTransactionToReview({})
    // refreshTransactions
  }
  function handleModalTransactionChange(transaction) {
    setTransactionToReview(transaction)
  }

  // const fetchTransactionData = useCallback(async () => {
  //   await refreshTransactions(state.userToken)
  //   let newTransactionsData = await listTransactions(state.userToken, 2023, 1, false)
  //   console.log(newTransactionsData)
  //   if(newTransactionsData){
  //     setNewTransactions(newTransactionsData)
  //   }
  //   let processedTransactionsData = await listTransactions(state.userToken, 2023, 1, true)
  //   if(processedTransactionsData){
  //     setProcessedTransactions(processedTransactionsData)
  //   }
  // }, [])

  
const prevMonth = () => {
    setCurrentMonthYear((current) => {
        let updated = {'year': current.year, 'month': current.month}
        if (current.month == 0) {
          updated.month = 11
          updated.year = current.year - 1
        } else {
          updated.month = current.month - 1
        }
        return updated
    });
  };

const nextMonth = () => {
    setCurrentMonthYear((current) => {
        let updated = {'year': current.year, 'month': current.month}
        if (current.month == CURRENT_MONTH && current.year == CURRENT_YEAR) {
            return current
        }

        if (current.month == 11) {
            updated.month = 0
            updated.year = current.year + 1
        } else {
            updated.month = current.month + 1
        }
        return updated
    })
}

  // const fetchCategoryData = useCallback(async () => {
  //   let data = await listCategories(state.userToken)
  //   console.log(categories)
  //   setCategories(data)
  // }, [])

  // useEffect(() => {
  //   // fetchTransactionData()
  //   fetchCategoryData()
  // }, [
  //   // fetchTransactionData,
  //   fetchCategoryData
  // ])



  return (
    // <SafeAreaView className="flex-1 justify-between bg-stone-100 px-4 pt-8">
    <ScreenContainer>
      <View className="flex-initial flex-row justify-end items-center" >
        <View className="flex-col">

          <Text className="text-3xl text-slate-800 font-bold uppercase">{monthText}</Text>
          <View className="flex-row">

            <Text className="mr-2" onPress={prevMonth}>&lt; prev</Text>
            {!(currentSelectedMonthYear.month == CURRENT_MONTH && currentSelectedMonthYear.year == CURRENT_YEAR) && (
              <Text onPress={nextMonth}>next &gt;</Text>
            )}
          </View>
        </View>
        {/* <Text>{'<prev'}</Text> */}
        <View className="grow"/>
        {/* <View  className="grow"></View> */}
        {/* <Pressable className="bg-slate-300 p-1 rounded-xl" onPress={() => navigation.navigate('Settings')}>
          <Ionicons name="settings-outline" size={32} />
        </Pressable>  */}
        {/* <Link to={{ screen: 'Settings'}}>
          +
        </Link> */}
        <Pressable className="bg-slate-300 px-1 rounded-xl mr-2"
          onPress={() => {
            setTransactionToReview({transaction_id: "NEW"})
            setModalVisible(true)
          }}
        >
          <Ionicons name="add" size={22} />
        </Pressable> 
        
        <Link to={{ screen: 'Settings'}} className="bg-slate-300 p-1 rounded-xl">
          Settings
        </Link>

        
      </View>

      {/* <View className="flex-row">
        <View className="flex-1 basis-1/2 pr-2">
          <View className="flex-col items-center py-2 my-1 rounded-xl bg-white shadow-sm">
            <View className="py-2 flex-col items-center">
              <Text className="mb-2 font-semibold text-slate-700">Monthly Spending</Text>
              <Text className="font-bold">$3,138 spent</Text>
              <Text className="text-slate-500 text-xs">$3,743 spent last month</Text>
            </View>
          </View>
        </View>
        <View className="flex-col basis-1/2 pl-2 bg-red-500 items-stretch">
          <View className="flex-col justify-center py-2 my-1 rounded-xl bg-white shadow-sm">
            <View className="px-2 flex-row items-center">
              <Pressable
                onPress={() => {
                  console.log("prev")
                  prevMonth()
                }}
              >
                <Text className="px-2 font-semibold text-slate-700">{monthText}</Text>
              </Pressable>
              <View className="grow"></View>
              <Pressable
              onPress={() => {
                console.log("next ")
                nextMonth()
              }}
              >

              <Ionicons name="chevron-forward" size={32} />
              </Pressable>
            </View>
         </View> 
        </View>
      </View> */}


      
      {newTransactions.length ? (
      <View className="flex-col items-center w-full py-1 px-4 my-1 rounded-xl bg-white shadow-sm">
        <View className="py-2">
          <Text className="text-slate-600 text-xs font-semibold">To Review</Text>
        </View>
        <View className="flex-col w-full">
        {newTransactions.filter((_, idx) => idx < 4).map(data => {
            let category = categoriesList.find(x => x.id == data.category_id)
            return (
              <Pressable key={data.id} 
                onPress={() => {
                  setTransactionToReview(data)
                  setModalVisible(true)
                }}
              >
                <View className="flex-row w-full items-center py-1">
                  <View className="basis-6/12 pr-1"><Text className="text-slate-700" numberOfLines={1}>{data.title}</Text></View>
                  <View className="flex-row basis-4/12">
                    {data.category_id && (
                      <View className="align-end py-1 px-2 rounded-full bg-slate-100">
                        <Text numberOfLines={1} className="truncate text-slate-800 text-xs font-bold">
                        {category.emoji} {category.title}
                        </Text>
                      </View>
                    )}
                  </View>
                  <View className="basis-2/12 flex-row justify-end"><Text numberOfLines={1} className={((data.amount < 0) ? "text-green-700" : '')}>{Math.abs(data.amount)}</Text></View>
                </View>
              </Pressable>
            );
          })}
        </View>
        <View className="h-px my-1 w-full bg-slate-300"></View>
        <Pressable
          className="py-3 w-full items-center"
          onPress={() => setModalVisible(true)}
        >
          <Text className="text-slate-700">Review</Text>
        </Pressable>
      </View>
      ) : (
        <View className="flex-col items-center">
          <View className="items-center py-3 w-full py-1 px-4 my-1 rounded-lg shadow-sm border-dashed border-2 border-slate-400">
            <Text className="uppercase text-slate-500 text-xs font-semibold">no new transactions</Text>
          </View>
        </View>
      )}

      <TransactionModal transaction={transactionToReview} modalVisible={modalVisible} handleClose={handleModalClose} handleTransactionChange={handleModalTransactionChange}/>


      <ScrollView
        horizontal={true}
        showsHorizontalScrollIndicator={false}
        className="grow-0 shrink-0 py-1 my-2"
      >
        {categoriesTotalsList.map(data => {
          return (
            <View key={data.id} className="w-16 justify-center items-center py-2 px-2 mr-2 rounded-xl bg-white shadow-sm">
              <Text>{data.emoji}</Text>
              <Text className="text-slate-700">${Math.trunc(Math.abs(data.total)) || 0}</Text>
            </View>
          );
        })}
        </ScrollView>
 
      <View className="flex-col">
      <Text className="text-lg font-bold text-slate-500">Recent</Text>
      <View className="flex-col">
        {processedTransactions.filter((_, idx) => idx < 4).map(data => {
          let category = categoriesList.find(x => x.id == data.category_id)
          return (
            <Pressable key={data.transaction_id} 
              onPress={() => {
                setTransactionToReview(data)
                setModalVisible(true)
              }}
            >
              <TransactionCard
                title={data.title}
                emoji={category ? category.emoji : ''}
                date={data.date}
                amount={data.amount}
                excluded={category && category.type_id == 2}
              />
            </Pressable>
          );
        })}
      </View>

      <View className="flex-row justify-center">
        <Link to={{ screen: 'Transactions'}}>
          + view all
        </Link>
      </View>

      </View>

      {/* <View className="grow"/> */}
      </ScreenContainer>

);
}