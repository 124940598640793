import { View, Text} from 'react-native';
// import { PlaidLink } from 'react-plaid-link';

import { SafeAreaView } from 'react-native-safe-area-context';


// let styles = require('./style');

import "../styles";


export const LoadingScreen = ({navigation}) => {
    return (
      <SafeAreaView className="flex-1 items-center justify-center bg-white">
        <Text className="text-6xl font-bold uppercase">Loading..</Text>
      </SafeAreaView>
      );
}