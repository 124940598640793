// Refresh Transactions

import { data } from "autoprefixer";


// Create Transaction (Manual Entry)
export async function createTransaction(token, transaction_id, date, title, category_id, split_parent_id=null, amount, account_id) {
    const address = 'https://api.lofi.money';
    var bearer = 'Bearer ' + token;

    // let url = `${address}/api/transactions${query_params}`

    const response = await fetch(
        `${address}/api/transactions`,
        {
            method: 'POST',
            headers: {
                Authorization: bearer,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                transaction_id: transaction_id,
                date: date,
                title: title,
                category_id: category_id,
                split_parent_id: split_parent_id,
                amount: amount,
                account_id: account_id,
                processed: true,
                raw_transaction: {}
                // date: '',

                // transaction_id: raw_transaction.transaction_id,
                // account_id: raw_transaction.account_id,
                // amount: processed.amount,
                // title: processed.title,
                // vendor: processed.merchant,
                // category_id: processed.category_id,
                // date: raw_transaction.date + "T00:00:00Z",
                // raw_transaction: raw_transaction,
            })
        }
    );
    const json = await response.json();
    console.log(json)
    return json
}

// Update Transaction
export async function updateTransaction(token, transaction_id, title, category_id, split_parent_id=null, amount=null) {
    // console.log(raw_transaction)
    // console.log("id")
    // console.log(raw_transaction.transaction_id)
    // superagent
    //     .patch(`${import.meta.env.VITE_API_HOST}/api/transactions/${raw_transaction.transaction_id}`)
    //     .send({
    //         // account_id: raw_transaction.account_id,
    //         amount: processed.amount,
    //         title: processed.title,
    //         vendor: processed.merchant,
    //         category_id: processed.category_id,
    //         // category: null,
    //         // raw_transaction: raw_transaction,
    //         // processed
    //     })
    //     .auth(get(authToken), { type: 'bearer' })
    //     .then(data => {
    //         console.log("transaction updated")
    //         // console.log(data)

    //         // for (let transaction of data.body) {
    //         //     addTransaction(transaction, true)
    //         // }
    //     });
    const address = 'https://api.lofi.money';
    var bearer = 'Bearer ' + token;

    // let url = `${address}/api/transactions${query_params}`

    let data = {
        title: title,
        category_id: category_id,
        split_parent_id: split_parent_id,
        processed: true
    }

    if (amount) {
        data.amount = amount
    }

    const response = await fetch(
        `${address}/api/transactions/${transaction_id}`,
        {
            method: 'PATCH',
            headers: {
                Authorization: bearer,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }
    );
    const json = await response.json();
    console.log(json)
    return json
}

// (Soft) Delete Transaction
export async function deleteTransaction(token, transaction_id) {

    const address = 'https://api.lofi.money';
    var bearer = 'Bearer ' + token;

    // let url = `${address}/api/transactions${query_params}`

    const response = await fetch(
        `${address}/api/transactions/${transaction_id}`,
        {
            method: 'DELETE',
            headers: {
                Authorization: bearer,
                'Content-Type': 'application/json'
            }
        }
    );
    const json = await response.json();
    console.log(json)
    return json
}


// List Transactions
export async function listTransactions(token, year, month, processed = null) {
    // superagent
    //     .get(`${import.meta.env.VITE_API_HOST}/api/transactions`)
    //     .query({year: year, month: month})
    //     .auth(get(authToken), { type: 'bearer' })
    //     .then(data => {

    //         console.log("api transactions")
    //         console.log(data.body)

    //         for (let transaction of data.body) {
    //             addTransaction(transaction, true)
    //         }
    //         console.log("svelte saved transactions")
    //         console.log(get(transactions))
    //     });
    const address = 'https://api.lofi.money';
    var bearer = 'Bearer ' + token;

    let params = {}
    if (year) {
        params.year = year
    }
    if (month){
        params.month = month
    }
    if (processed !== null) {
        params.processed = processed
    }

    let query_params = params ? new URLSearchParams(params) : '' 

    // let url = `${address}/api/transactions${query_params}`

    const response = await fetch(
        `${address}/api/transactions?${query_params.toString()}`,
        {
            method: 'GET',
            headers: {Authorization: bearer}
        }
    );
    const json = await response.json();
    return json
}

// Refresh Transactions
export async function refreshTransactions(token) {
    const address = 'https://api.lofi.money';
    var bearer = 'Bearer ' + token;

    const response = await fetch(
        `${address}/api/plaid/transactions`,
        {
            method: 'GET',
            headers: {Authorization: bearer}
        }
    );
}