import superagent from "superagent";


// Refresh Token
export async function refreshToken() {
    const address = 'https://api.lofi.money';
    // const address = 'localhost:8000'; // IOS
    // const address = ''; // WEB

    let response = await fetch(
        `${address}/api/users/retrive_device_refresh_token`,
        {
            credentials: 'include',
            headers: {
                "Access-Control-Allow-Origin": "https://app.lofi.money",
            },
        }
    );
    const json = await response.json();
    return json.hasOwnProperty('token') ? json.token : null
}

// Signin
export async function signIn(username, password) {
	const form_data = new FormData()
	form_data.append("username", username)
	form_data.append("password", password)

    const address = 'https://api.lofi.money';
    // const address = 'localhost:8000'; // IOS
    // const address = ''; // WEB
    const response = await fetch(
        `${address}/api/users/signin`,
        {
            method: 'POST',
            body: form_data,
            credentials: 'include',
            headers: {
                "Access-Control-Allow-Origin": "https://app.lofi.money",
            },
        }
    );
    const json = await response.json();
    return json.token
};

// Signout
export async function signOut(token) {
    superagent
        // .get(`${import.meta.env.VITE_API_HOST}/api/users/signout`)
        .auth(token, { type: 'bearer' })
        .then(res => {
            console.log("signout")
            // setToken(null)
            // goto("/about")
        });
};
