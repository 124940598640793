import { Platform, View, Text, Button, Pressable } from 'react-native';
import { DateTime } from "luxon";

export const TransactionCard = ({ title, emoji, date, amount, excluded=false}) => (
    <View className={"flex-row items-center w-full py-3 px-4 my-1 rounded-xl bg-white shadow-sm " + (excluded ? 'bg-zinc-50' : '')}>
      <Text className="pr-2">{emoji}</Text>
      <View className="flex-col basis-7/12">
        <Text numberOfLines={1} className={(excluded ? 'text-slate-500' : 'text-slate-700')}>{title}</Text>
        {!excluded && (

        <Text className="text-xs text-slate-500">{DateTime.fromISO(date).toFormat('EEE, MMM dd')}</Text>
        )}
      </View>
      <View className="grow"></View>
      <Text className={(excluded ? 'text-slate-500 ' : ((amount < 0) ? " text-green-700" : 'text-slate-700'))}>{(amount < 0) ? "+" : '-'} ${Math.abs(amount)}</Text>
    </View>
  );
  