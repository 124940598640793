import { Platform, View, Text, Button, Pressable } from 'react-native';
import Ionicons from '@expo/vector-icons/Ionicons';
import { CommonActions } from '@react-navigation/native';
import { Link } from '@react-navigation/native';

export const Header = ({navigation}) => (
    <View className="flex-initial flex-row items-center mb-4">
    <Link to={{ screen: 'Home'}}>
        Home
    </Link>
    {/* <Pressable className="bg-slate-300 p-1 rounded-xl" onPress={() => navigation.dispatch(CommonActions.goBack())}>
        <Ionicons name="chevron-back" size={32} />
    </Pressable>  */}
    </View>
  );
  