import { View, Text, Button, Platform, TextInput} from 'react-native';
// import { PlaidLink } from 'react-plaid-link';
import { useGlobalState } from '../store/AuthStore';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useState } from 'react';
// import { signIn, refreshToken } from '../data/auth';
import React from 'react';
import { AuthContext } from '../store/AuthStore';

// let styles = require('./style');

// import "../styles";


export const SignInScreen = ({navigation}) => {


    const [state, dispatch] = useGlobalState();
    const { signIn } = React.useContext(AuthContext);
    // const address = Platform.OS === 'ios' ? 'http://localhost:8000' : '';
    // const address = 'https://api.lofi.money';
    const [username, setUsername] = useState();
    const [password, setPassword] = useState();
    // const signIn = async () => {
    //     try {
    //         const form_data = new FormData()
    //         form_data.append("username", username)
    //         form_data.append("password", password)
    //         const response = await fetch(
    //             `${address}/api/users/signin`,
    //             {
    //                 method: 'post',
    //                 body: form_data,
    //             }
    //         );
    //         const json = await response.json();
    //         dispatch({ userToken: json.token })
    //     } catch (error) {
    //         console.error(error);
    //     }
    //   };
    const handleSignIn = async () => {
      signIn({ username, password })
      // let token = await signIn(username, password)
      // dispatch({ userToken: token })
    };
    return (
      <SafeAreaView className="flex-1 items-center justify-center bg-white">
        <Text className="text-6xl font-bold uppercase bg-sky-500 hover:bg-sky-700">SignIn</Text>
        <TextInput
        className="w-2/3 p-2 m-2 border text-center"
        onChangeText={setUsername}
        value={username}
        placeholder={"enter email"}
        keyboardType={"email-address"}
        autoCapitalize='none'
      />
      <TextInput
        className="w-2/3 p-2 m-2 border text-center"
        onChangeText={setPassword}
        value={password}
        placeholder={"enter password"}
        secureTextEntry={true}
        autoCapitalize='none'
      />
        <Button onPress={handleSignIn} title="Start"/>
      </SafeAreaView>
      );
}