
import React from 'react';
import { Text, View, TouchableOpacity } from "react-native";
import Modal from "react-native-modal";


export function CustomModal ({children, modalVisible, handleClose}) {
    return (
      <Modal
        isVisible={modalVisible}
        onBackdropPress={() => handleClose()}
        className="mx-2"
      >
        <View className="h-full w-full max-h-96 max-w-lg mx-auto bg-slate-200 rounded-2xl">
          <View className="py-4 h-full flex-col items-center">
            {children}
            {/* <View className="grow"/>
            <TouchableOpacity
              onPress={() => {handleClose();}}
            >
            <Text>Close</Text>
          </TouchableOpacity> */}
          </View>
        </View>
      </Modal>
    )
}