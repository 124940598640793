

import React from 'react';
import { Text, View, Pressable, ScrollView} from "react-native";
import { SafeAreaView } from 'react-native-safe-area-context';
import { Header } from '../components/Header';
import { useState, useCallback, useEffect } from 'react';
import { CustomModal } from '../components/CustomModal/CustomModal';
import { ScreenContainer } from '../components/ScreenContainer/ScreenContainer';
import { TransactionCard } from '../components/TransactionCard';
import { listTransactions } from '../data/transactions';
import { listCategories } from '../data/categories';
import { useGlobalState } from '../store/AuthStore';
import Ionicons from '@expo/vector-icons/Ionicons';
import { useRecoilValue, useRecoilRefresher_UNSTABLE } from 'recoil';
import { transactions, categories, monthsProcessedTransactions, userTokenState, currentMonthText, monthsTotal } from '../store/TransactionStore';
import { TransactionModal } from '../components/TransactionModal';


export const TransactionsScreen = ({navigation}) => {
    const [state, dispatch] = useGlobalState();
    const [modalVisible, setModalVisible] = useState(false);
    const [processedTransactions, setProcessedTransactions] = useState([]);
    // const [categories, setCategories] = React.useState([]);
    const transactionsList = useRecoilValue(monthsProcessedTransactions);
    const categoriesList = useRecoilValue(categories);
    const monthText = useRecoilValue(currentMonthText)
    const total = useRecoilValue(monthsTotal)
    const refreshTransactionState = useRecoilRefresher_UNSTABLE(transactions);

    const [transactionToReview, setTransactionToReview] = useState(transactionsList[0]);
    function handleModalClose(refreshTransactions) {
      if (refreshTransactions){
        refreshTransactionState()
      }
      setModalVisible(false)
    }
    function handleModalTransactionChange(transaction) {
      setTransactionToReview(transaction)
    }


    // const fetchCategoryData = useCallback(async () => {
    //   let data = await listCategories(state.userToken)
    //   setCategories(data)
    // }, [])
  
    // useEffect(() => {
    //   fetchCategoryData()
    // }, [fetchCategoryData])
    
    return (
        <ScreenContainer>
            <TransactionModal transaction={transactionToReview} modalVisible={modalVisible} handleClose={handleModalClose} handleTransactionChange={handleModalTransactionChange}/>


            <Header navigation={navigation}/>

            <View className="h-px w-full bg-slate-300"></View>
      
            <View className="my-4 mx-2 flex-row">
              <Text className="text-xl font-bold text-slate-700">{monthText}</Text>
              <View className="grow"/>
              <Text className={'font-bold ' + ((total < 0) ? "text-green-700" : 'text-red-600')}>${Math.abs(total)}</Text>
            </View>
          <ScrollView showsVerticalScrollIndicator={false}>
          {/* {processedTransactions.map(data => { */}
          {transactionsList.map(data => {
          let category = categoriesList.find(x => x.id == data.category_id)
          return (
            <Pressable key={data.transaction_id} 
              onPress={() => {
                setTransactionToReview(data)
                setModalVisible(true)
              }}
            >

              <TransactionCard
                key={data.transaction_id}
                title={data.title}
                emoji={category ? category.emoji : ''}
                date={data.date}
                amount={data.amount}
                excluded={(category && category.type_id === 2)}
              />
            </Pressable>
          );
        })}

            </ScrollView>
        </ScreenContainer>
      );
}