import {
    RecoilRoot,
    atom,
    selector,
  } from 'recoil';
import currency from 'currency.js';

import { DateTime } from "luxon";
import { listTransactions } from '../data/transactions';
import { listCategories } from '../data/categories'

let today = new Date();
export const CURRENT_MONTH = today.getMonth()
export const CURRENT_YEAR = today.getFullYear()

export const userTokenState = atom({
    key: 'userTokenState',
    default: '',
});

export const currentMonthYear = atom({
    key: 'currentMonthYear',
    default: {
        'year': CURRENT_YEAR,
        'month': CURRENT_MONTH
    },
});

export const currentMonthText = selector({
    key: 'currentMonthText',
    get: ({get}) => {
        const monthsList = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        return monthsList[get(currentMonthYear).month];
    }
});


export const categories = atom({
    key: 'categories',
    default: selector({
        key: 'categoriesLoader',
        get: async ({get}) => {
            let token = get(userTokenState)
            if (token){
                let categories = await listCategories(token)
                if (categories instanceof Array) {
                    return categories
                }
            }
            return []
        }
      }),
  });

  export const monthsCategoryTotals = selector({
    key: 'monthsCategoryTotals',
    get: ({get}) => {
        const categoriesList = get(categories);
        const transactionsList = get(transactions)
        let totals = {}

        transactionsList.map((transaction, _) => {
            let id = transaction.category_id
            id in totals || (totals[id] = 0);
            totals[id] = currency(totals[id]).add(transaction.amount);
        })

        return categoriesList.filter(
            (category) => !(category.type_id == 2)
        ).map((category) => ({
            ...category,
            total: totals[category.id]
        }))

    }
});


  
export const transactions = atom({
    key: 'transactions',
    default: selector({
        key: 'transactionsLoader',
        get: async ({get}) => {
            let token = get(userTokenState)
            const monthYear = get(currentMonthYear);
            const month = monthYear.month
            const year = monthYear.year
            if (token){
                let transactions = await listTransactions(token, year, month + 1)
                if (transactions instanceof Array) {
                    return transactions
                }
            }
            return []
        }
      }),
  });

export const monthsTransactions = selector({
    key: 'monthsTransactions',
    get: ({get}) => {
        const list = get(transactions);
        const monthYear = get(currentMonthYear);
        const month = monthYear.month
        const year = monthYear.year

        return list.filter((transaction) => (
            month == DateTime.fromISO(transaction.date).month -1 && year == DateTime.fromISO(transaction.date).year
        ))
    }
});

export const monthsProcessedTransactions = selector({
    key: 'monthsProcessedTransactions',
    get: ({get}) => {
        const list = get(monthsTransactions);

        return list.filter((transaction) => (transaction.processed));
    }
});

export const monthsUnprocessedTransactions = selector({
    key: 'monthsUnprocessedTransactions',
    get: ({get}) => {
        const list = get(monthsTransactions);

        return list.filter((transaction) => !(transaction.processed));
    }
});

export const monthsTotal = selector({
    key: 'monthsTotal',
    get: ({get}) => {
        const list = get(monthsProcessedTransactions);
        const cats = get(categories)
        let total = 0
        list.map((transaction, _) => {
            let category = cats.find(x => x.id == transaction.category_id)
            if (!(category && category.type_id == 2)){
                total = currency(total).add(transaction.amount)
            }
        })
        return total
    }
});
