
import React from 'react';
import { Text, View, TouchableOpacity, TextInput, ScrollView, Pressable } from "react-native";
import { CustomModal } from '../components/CustomModal/CustomModal';
import { listCategories } from '../data/categories';
import { useGlobalState } from '../store/AuthStore';
import { useCallback, useEffect } from 'react';
import { updateTransaction, createTransaction, deleteTransaction } from '../data/transactions';
import { useRecoilValue } from 'recoil';
import { categories, transactions } from '../store/TransactionStore';
import { DateTime } from "luxon";
import { Ionicons } from '@expo/vector-icons';
import { v4 as uuidv4 } from 'uuid'; 

import currency from 'currency.js';

export function TransactionModal ({modalVisible, handleClose, transaction, handleTransactionChange}) {

  if (!transaction) {
    return (<></>)
  }

  const [state, dispatch] = useGlobalState();
  const [title, onChangeTitle] = React.useState(transaction.title);
  const [originalAmount, setOriginalAmount] =  React.useState(transaction.amount);
  const [amount, setAmount] = React.useState(transaction.amount);
  const [selectedCategory, setCategory] = React.useState(null);
  const [splitView, setSplitView] = React.useState(false);
  // const [categories, setCategories] = React.useState([]);
  const categoriesList = useRecoilValue(categories);
  const transactionsList = useRecoilValue(transactions);
  let selectedCategoryData = categoriesList.find(item => item.id === selectedCategory)


  const [splits, setSplits] = React.useState([{amount: 0, category_id: transaction.category_id}]);
  const [oldSplits, setOldSplits] = React.useState([]);
  
  // const used = splits.reduce(function(a, b){
    
    //     return a + b.amount;
    // })


    const openSplitView = () => {
      if (transaction.split_parent_id) {
        let parentTransaction = transactionsList.find(t => {
          return (
            (t.split_parent_id === transaction.split_parent_id)
            && (t.split_parent_id === t.id)
          )
        })
        
        let relatedSplits = transactionsList.filter(t => {
          return (
            (t.split_parent_id === transaction.split_parent_id)
            // Remove the parent transaction
            && (t.transaction_id !== parentTransaction.transaction_id)
          )
        })
        // Save oldSplits for update reasons
        setOldSplits(relatedSplits)
        console.log("TOEDIT", relatedSplits, oldSplits)
        let splitsToEdit = []
        relatedSplits.map(split => {
          splitsToEdit.push({amount: split.amount, category_id: split.category_id})
        })
        console.log("HEREGOS", splitsToEdit)
        handleTransactionChange(parentTransaction)
        console.log("AGAIN", splitsToEdit)
        setSplits(splitsToEdit)
        // Set original amount to equal total of splits + parent transaction
        setOriginalAmount(relatedSplits.reduce((accumulator, currentValue) => {
          return accumulator + (Number.parseFloat(currentValue.amount) || 0)
        }, Number.parseFloat(parentTransaction.amount) || 0))

      }
      setSplitView(true)
    }
    
    const calculateAmountAfterSplit = (splits) => {
      const used = splits.reduce((accumulator, currentValue) => {
        return accumulator + (Number.parseFloat(currentValue.amount) || 0)
      },0);
      setAmount(originalAmount - used)
    }
    

    const handleSplitFormChange = (index, text, key) => {
      if (key === "amount"){
        let neg = false
        if (originalAmount < 0) {
          neg = true
        }
        let remaining = Math.abs(originalAmount)
        const otherSplits = splits.filter((_, i) => i !== index)
        const used = otherSplits.reduce((accumulator, currentValue) => {
          return accumulator + (Number.parseFloat(currentValue.amount) || 0)
        },0);
        remaining -= used
  
        let data = [...splits];
        const parsedText = Number.parseFloat(text) || 0
  
        if (parsedText <= remaining) {
          data[index][key] = text;
          setSplits(data);
          if (neg) {
            
            setAmount(currency(originalAmount).add(used + parsedText))
          } else {
            setAmount(currency(originalAmount).subtract(used + parsedText))
          }
        }
      } else if (key === "category_id") {
        let data = [...splits];
        data[index][key] = text;
        setSplits(data);
      }
    }

    const handleRemoveSplit = (index) => {
      let data = [...splits];
      let removedSplit = data[index]
      let updatedSplits = data.filter((_, i) => i !== index)
      setSplits(updatedSplits)
      setAmount(current => currency(current).add(removedSplit.amount))
      // calculateAmountAfterSplit(updatedSplits)
    }

  useEffect(() => {
    if (transaction.transaction_id === "NEW"){
      transaction = {
        transaction_id: uuidv4(),
        title: 'Title',
        date: DateTime.now().toISO(),
        category_id: null,
        amount: 0,
        raw_transaction: {}
      }
    }
    // If the transaction has changed since last opened
    onChangeTitle(transaction.title);
    setCategory(transaction.category_id || null)
    setAmount(transaction.amount)
    setOriginalAmount(transaction.amount)
    if (!splits) {
      // setSplitView(false)
      setSplits([{amount: 0, category_id: transaction.category_id || null}])
    }
  }, [transaction.transaction_id]);



  const handleModalClose = (refreshTransaction) => {
    setSplitView(false)
    setSplits([])
    handleClose(refreshTransaction)
  }
  
  const handleSubmit = (async (isSplit=false) => {
    
    let split_parent_id = null
    let amount_update = null
    if (isSplit) {
      // If splits already exist, delete all so that new ones can be made
      if (transaction.split_parent_id) {
        console.log("Out with the old", oldSplits)
        oldSplits.map(async split => {
          deleteTransaction(state.userToken, split.transaction_id)
        })
       }
   
      // TODO wont have transation id when creating new transaction
      // TODO wont have transaction.account_id when creating new transaction
      let nonEmptySplits = splits.filter(split => split.amount > 0)
      // only if there are splits
      if (nonEmptySplits) {
        split_parent_id = transaction.transaction_id
        nonEmptySplits.map(async split => {
          await createTransaction(state.userToken, uuidv4(), DateTime.now().toISO(), transaction.title, split.category_id, split_parent_id=split_parent_id, split.amount, transaction.account_id)
        })
      } else {
        split_parent_id = null
      }
      // Update amount of parent transaction
      amount_update = amount
    }

    if (transaction.raw_transaction){
      await updateTransaction(state.userToken, transaction.transaction_id, title, selectedCategory, split_parent_id, amount_update)
      handleModalClose(true)
    } else {
      // TODO: In progress
      await createTransaction(state.userToken, transaction, split_parent_id=split_parent_id)
    }
  })

    return (
      <CustomModal modalVisible={modalVisible} handleClose={handleModalClose}>

        {splitView ? (
          <View className="flex-col items-center w-full">
            <Text className="mt-2 text-sm text-slate-500 uppercase">Split Transaction</Text>
            <Text className="w-5/6 text-center mt-2 text-2xl font-bold text-slate-700">{title}</Text>
            <Text className={"text-lg font-bold text-slate-700 " + ((amount < 0) ? "text-green-700" : '')}>{(amount < 0) ? "+" : '-'} ${Math.abs(amount)}</Text>
          
            {/* TODO: Not currently handling overflow */}
            <View className="h-px my-1 w-2/3 bg-slate-300"></View>
            <View className="flex-row w-5/6 items-center py-2 px-8 ">
              <View className="flex-row basis-6/12">
                <Text numberOfLines={1} className={((amount < 0) ? "text-green-700" : '')}>{Math.abs(amount)}</Text>
                {/* <TextInput
                  onChangeText={text => handleSplitFormChange(index, text, "amount")}
                  value={data.amount}
                /> */}
              </View>
              <View className="flex-row basis-4/12  justify-end">
                {(selectedCategory !== null)  && (
                  <View className="align-end py-1 px-2 rounded-full bg-slate-100">
                    <Text numberOfLines={1} className="truncate text-slate-800 text-xs font-bold">
                    {selectedCategoryData.emoji} {selectedCategoryData.title}
                    </Text>
                  </View>
                )}
              </View>
            </View>
            <View className="h-px my-1 w-2/3 bg-slate-300"></View>
            {/* <ScrollView className="w-full  items-center"> */}
              {splits.map((split, index) => {
                let splitCategory
                if (split.category_id) {
                  splitCategory = categoriesList.find(item => item.id === split.category_id)
                }
                return (
                  <>
                  {split.category_id ? (
                  <View className="flex-row w-5/6 items-center py-2 px-8">
                    <View className="flex-row basis-6/12">
                      <TextInput
                        onChangeText={text => handleSplitFormChange(index, text, "amount")}
                        value={split.amount}
                      />
                    </View>
                    <View className="flex-row basis-4/12  justify-end">
                      <Pressable onPress={() => handleSplitFormChange(index, null, "category_id")}>
                        <View className="align-end py-1 px-2 rounded-full bg-slate-100">
                          <Text numberOfLines={1} className="truncate text-slate-800 text-xs font-bold">
                            {splitCategory.emoji} {splitCategory.title}
                          </Text>
                        </View>
                      </Pressable>
                    </View>
                    <Pressable
                      onPress={() => {
                        handleRemoveSplit(index)
                      }}
                      className="flex-row basis-2/12 justify-end"
                    >
                      <Text>x</Text>
                    </Pressable>
                  </View>
                  ) : (
                    <ScrollView
                      horizontal={true}
                      showsHorizontalScrollIndicator={false}
                      className="pl-4 py-2 w-full"
                    >
                      {categoriesList.map(category => {
                        return (
                          <Pressable key={category.id} onPress={() => handleSplitFormChange(index, category.id, "category_id")}>
                            <View className="align-end py-1 px-2 mr-2 rounded-full bg-slate-100"><Text numberOfLines={1} className="truncate text-slate-800 text-xs font-bold">{category.emoji} {category.title}</Text></View>
                          </Pressable>
                        );
                      })}
                    </ScrollView>
                  )
                }
                  <View className="h-px my-1 w-2/3 bg-slate-300"></View>
                  </>
                  
                );
              })}
            {/* </ScrollView> */}
            
            <Pressable onPress={() => setSplits(oldArray => [...oldArray, {amount: 0, category_id: selectedCategory}])}
              className="my-2">
              <Text>+ add</Text>
            </Pressable>
            
            
            <View className="h-px my-2 w-2/3 bg-slate-300"></View>
            <View className="flex-row">
            <Pressable
                className="py-3 w-2/3 items-center border"
                onPress={() => {
                  setSplitView(false)
                  setSplits([])
                }}
              >
              <Text>Cancel</Text>
            </Pressable>

            <Pressable
              className="py-3 w-2/3 items-center border"
              onPress={() => handleSubmit(true)}
            >
              <Text>Save</Text>
            </Pressable>
          </View>
          </View>
        ) : (
          <View className="flex-col items-center w-full">

          
          {transaction.raw_transaction && (
            <>
              <Text className="mt-2 text-sm text-slate-500">{DateTime.fromISO(transaction.date).toFormat('EEEE, MMM dd')}</Text>
              <Text className="mt-1 px-4 text-xs text-slate-500" numberOfLines={1}>{transaction.raw_transaction.name}</Text>
            </>
          )} 

          {/* <View className="flex-row items-center w-full"> */}
            <TextInput
              onChangeText={onChangeTitle}
              value={title}
              className="w-5/6 text-center mt-2 text-2xl font-bold text-slate-700"
            />
            {/* <Pressable>
            <Ionicons name="ios-close-circle" size={18} color="grey" />
            </Pressable> */}
          {/* </View> */}
          
          
          <Text className={"mt-2 text-3xl font-bold text-slate-700 " + ((amount < 0) ? "text-green-700" : '')}>{(amount < 0) ? "+" : '-'} ${Math.abs(amount)}</Text>
          {/* <TextInput
            editable={false}
            onChangeText={(text)=> setAmount(text.replace(/[^0-9]/g, ''))}
            keyboardType='numeric'
            value={amount}
            className={"mt-2 text-3xl font-bold text-slate-700 " + ((amount < 0) ? "text-green-700" : '')}
          /> */}
          {/* <Text className={((amount < 0) ? "text-green-700" : '')}>{(amount < 0) ? "+" : '-'} ${Math.abs(amount)}</Text> */}


        {(selectedCategory !== null) ? (
          <Pressable className="py-4 mt-2" onPress={() => setCategory(null)}>
            <View key={selectedCategoryData.id} className="align-end py-1 px-2 mr-2 rounded-full bg-slate-50"><Text numberOfLines={1} className="truncate text-slate-800 text-xs font-bold">{selectedCategoryData.emoji} {selectedCategoryData.title}</Text></View>
          </Pressable>
        ) : (
        <ScrollView
          horizontal={true}
          showsHorizontalScrollIndicator={false}
          className="pl-4 py-4 mt-2 w-full"
        >
          {categoriesList.map(data => {
            return (
              <Pressable key={data.id} onPress={() => setCategory(data.id)}>
                <View className="align-end py-1 px-2 mr-2 rounded-full bg-slate-100"><Text numberOfLines={1} className="truncate text-slate-800 text-xs font-bold">{data.emoji} {data.title}</Text></View>
              </Pressable>
            );
          })}</ScrollView>
        )}
        <View className="h-px my-2 w-2/3 bg-slate-300"></View>
        <View className="flex-row">
          <Pressable
              className="py-3 w-2/3 items-center border"
              onPress={() => openSplitView()}
            >
            {transaction.split_parent_id ? (
              <Text>Edit Split</Text>
            ) : (
              <Text>Split</Text>
            )}
          </Pressable>

          <Pressable
            className="py-3 w-2/3 items-center border"
            onPress={() => handleSubmit(false)}
          >
            <Text>Mark Reviewed</Text>
          </Pressable>
        </View>
        </View>
        )}
      </CustomModal>
    )
}