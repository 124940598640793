

import React from 'react';
import { Text, View } from "react-native";
import { CustomPlaidLink } from '../components/CustomPlaidLink/CustomPlaidLink';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Header } from '../components/Header';


export const SettingsScreen = ({navigation}) => {
    return (
        <SafeAreaView className="p-4">
          <Header navigation={navigation}/>
          <View>
            <Text>Setting Screen</Text>
            <CustomPlaidLink/>
          </View>
        </SafeAreaView>
      );
}