import React from 'react';

const globalState = {
    isLoading: true,
    isSignout: false,
    userToken: null,
  };
const globalStateContext = React.createContext(globalState);
const dispatchStateContext = React.createContext(undefined);

export const GlobalStateProvider = ({ children }) => {
    const [state, dispatch] = React.useReducer(
        // (state, newValue) => ({ ...state, ...newValue }),
        (prevState, action) => {
            switch (action.type) {
              case 'RESTORE_TOKEN':
                return {
                  ...prevState,
                  userToken: action.token,
                  isLoading: false,
                };
              case 'SIGN_IN':
                return {
                  ...prevState,
                  isSignout: false,
                  userToken: action.token,
                };
              case 'SIGN_OUT':
                return {
                  ...prevState,
                  isSignout: true,
                  userToken: null,
                };
            }
        },
        globalState
    );
    return (
        <globalStateContext.Provider value={state}>
            <dispatchStateContext.Provider value={dispatch}>
                {children}
            </dispatchStateContext.Provider>
        </globalStateContext.Provider>
    );
};

export const useGlobalState = () => [
    React.useContext(globalStateContext),
    React.useContext(dispatchStateContext)
];

export const AuthContext = React.createContext();