import superagent from "superagent";

// List Categories
export async function listCategories(token, year, month) {
    const address = 'https://api.lofi.money';
    var bearer = 'Bearer ' + token;

    const response = await fetch(
        `${address}/api/categories`,
        {
            method: 'GET',
            headers: {Authorization: bearer}
        }
    );
    const json = await response.json();
    return json

    // superagent
    //     .get(`${address}/api/categories`)
    //     // .query({year: year, month: month})
    //     .auth(token, { type: 'bearer' })
    //     .then(res => {
    //         console.log(res.body)
    //         return res.body
    //         // for (let category of data.body) {
    //         //     addCategory(category)
    //         // }
    //     });
}