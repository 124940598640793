
import * as React from 'react';
import { Navigation } from './Navigation';
import { SafeAreaProvider} from 'react-native-safe-area-context'
import { GlobalStateProvider } from './store/AuthStore';
import { StatusBar } from 'expo-status-bar';
import { LoadingScreen } from './screens/LoadingScreen';
import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from 'recoil';

export default function App() {
  return (
    <SafeAreaProvider>
      <GlobalStateProvider>
        <RecoilRoot>
          <React.Suspense fallback={<LoadingScreen />}>
            <Navigation/>
            <StatusBar style='dark' />
          </React.Suspense>
        </RecoilRoot>
      </GlobalStateProvider>
    </SafeAreaProvider>
  );
}
