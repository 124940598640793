import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { useEffect } from 'react';
import { Platform } from "react-native";

import { HomeScreen } from './screens/HomeScreen';
import { SettingsScreen } from './screens/SettingsScreen';
import { SignInScreen } from './screens/SignInScreen';
import { LoadingScreen } from './screens/LoadingScreen';
import { useGlobalState } from './store/AuthStore';
import { TransactionsScreen } from './screens/TransactionsScreen';
import * as Linking from 'expo-linking';
import { signIn, refreshToken } from './data/auth';
import React from 'react';
import { AuthContext } from './store/AuthStore';
import { refreshTransactions } from './data/transactions';
import { useSetRecoilState } from 'recoil';
import { userTokenState } from './store/TransactionStore';

const prefix = Linking.createURL('/');

const Stack = createNativeStackNavigator();

export const Navigation = () => {
    const linking = {
        prefixes: [prefix],
        config: {
            screens: {
                initialRouteName: 'Home',
                Home: "",
                Settings: "settings",
                Transactions: "transactions"
              },
            // Feed:"feed" ,
            // NotFound: "404",
        },
    };

    const [state, dispatch] = useGlobalState();
    const setUserToken = useSetRecoilState(userTokenState);
    
    React.useEffect(() => {
        // Fetch the token from storage then navigate to our appropriate place
        const bootstrapAsync = async () => {
            let userToken;

            try {
                // userToken = await SecureStore.getItemAsync('userToken');
                if (Platform.OS == "web") {
                    userToken = await refreshToken();
                }
            } catch (e) {
            // Restoring token failed
            }

            // After restoring token, we may need to validate it in production apps

            // This will switch to the App screen or Auth screen and this loading
            // screen will be unmounted and thrown away.
            if(userToken) {
                dispatch({ type: 'RESTORE_TOKEN', token: userToken });
                refreshTransactions(userToken)
                setUserToken(userToken)
            }
        };

        bootstrapAsync();
    }, []);

    const authContext = React.useMemo(
        () => ({
            signIn: async (data) => {
                // In a production app, we need to send some data (usually username, password) to server and get a token
                // We will also need to handle errors if sign in failed
                // After getting token, we need to persist the token using `SecureStore`
                // In the example, we'll use a dummy token

                let token = await signIn(data.username, data.password)
                // dispatch({ userToken: token })

                dispatch({ type: 'SIGN_IN', token: token });
                refreshTransactions(token)
                setUserToken(token)
            },
            signOut: () => dispatch({ type: 'SIGN_OUT' }),
            signUp: async (data) => {
                // In a production app, we need to send user data to server and get a token
                // We will also need to handle errors if sign up failed
                // After getting token, we need to persist the token using `SecureStore`
                // In the example, we'll use a dummy token

                dispatch({ type: 'SIGN_IN', token: 'dummy-auth-token' });
                
            },
        }),
        []
    );
    


    // if (state.userToken == null) {
    //     let token = await refreshToken()
    //     if (token) {
    //         dispatch({ userToken: token })
    //     }
    // }


    // if (state.isLoading) {
    //     // We haven't finished checking for the token yet
    //     return <LoadingScreen />;
    // }

    return(
        <AuthContext.Provider value={authContext}>
            <NavigationContainer linking={linking} fallback={<LoadingScreen />}>
                <Stack.Navigator 
                    screenOptions={{
                        headerShown: false
                    }}
                    >
                    {state.userToken == null ? (
                    // {false ? (
                        // No token found, user isn't signed in
                    <Stack.Screen
                        name="SignIn"
                        component={SignInScreen}
                        options={{
                            headerShown: false,
                            title: 'Sign in',
                            // When logging out, a pop animation feels intuitive
                            // You can remove this if you want the default 'push' animation
                            animationTypeForReplace: state.isSignout ? 'pop' : 'push',
                        }}
                    />
                    ) : (
                    // User is signed in
                    <>
                        <Stack.Screen name="Home" component={HomeScreen} options={{headerShown: false}}/>
                        <Stack.Screen name="Transactions" component={TransactionsScreen} />
                        <Stack.Screen name="Settings" component={SettingsScreen} />
                    </>
                    )}
                </Stack.Navigator>
            </NavigationContainer>
        </AuthContext.Provider>
    )
}